.mini-map {
    position: absolute;
    bottom: 40px;
    left: 40px;
    pointer-events: all;
    width: 250px;
    height: 125px;
    box-shadow: rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
    background-color: #ffffff12;
    background-image: url(https://i.kym-cdn.com/entries/icons/original/000/018/937/VR_banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    opacity: .65;
    transform: scaleX(-1);
    border-radius: 3px;
    animation: fadeIn 1.5s ease-in-out;
    .mini-map-overlay {
        width: 100%;
        height: 100%;
        border: 1.5px solid #737373;
        transform: scaleX(-1);
        color: white;
        font-size: 13px;
        background: #f5deb300;
        border-radius: 3px;
    }
}