@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@500;700&family=Poppins:wght@300;400;500;600;700&family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@500;700&family=Poppins:wght@300;400;500;600;700&family=Quicksand&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gudea:ital,wght@0,400;0,700;1,400&family=Lateef:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

@font-face {
    font-family: "Ailerons";
    src:
        local("Ailerons"),
        url("../public/fonts/Ailerons.otf") format("opentype");
        font-display: swap;
}

@import '../public/styles/scss/UI.scss';
@import '../public/styles/scss/hero.scss';
@import '../public/styles/scss/animations.scss';