@keyframes appear {
    from {
        opacity: 0;
        transform: translateY(100%);
    }   

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }   

    to {
        opacity: 1;
    }
}

@keyframes loading {
    from, to {
        opacity: 0.3;
    }   

    50% {
        opacity: 1;
    }
}

@keyframes idleChevron {
    from {
        opacity: .9;
        transform: translateY(0) rotate(90deg);
    }   

    50% {
        opacity: .6;
        transform: translateY(20px) rotate(90deg);
    }

    to {
        opacity: .9;
        transform: translateY(0) rotate(90deg);
    }
}