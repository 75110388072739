$breakpoint-mobile: 490px;
$breakpoint-sm: 642px;
$breakpoint-md: 766px;
$breakpoint-xl: 1200px;

body {
    min-height: 100dvh;
    background-color: #1e2325;

    * {
        user-select: none;
    }
}

#root {
    width: 100vw;
    height: 100vh;
    
    .crosshair {
        width: 20px;
        height: 20px;
        position: absolute;
        background: url(../../focus5.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        top: 50vh;
        left: 50vW;
        z-index: 20000;
        opacity: .6;
    }

    .filter {
        width: 100%;
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        backdrop-filter: saturate(1.4);
        z-index: 1;
        background: #29295b0d;
    }

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background: none;
        z-index: 10;
        overflow: hidden;
        pointer-events: none;
        
        .main_titles {
            position: absolute;
            font-family: Anurati-Regular;
            color: white;
            right: 10vw;
            top: 36vh;
            text-align: center;
            pointer-events: all;
            z-index: 10;
            pointer-events: all;

            &.left {
                right: unset;
                left: 10vw;
            }

            h4 {
                margin: 0;
                background-image: linear-gradient(#ffc17f 30%, #ffc17f 100%);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 13px;
                color: #fbd5a4;
                text-shadow: 0 0 10px #cca57bc2;
                font-family: 'Play';
            }

            h3 {
                margin: 0;
                font-family: Ailerons;
                text-transform: uppercase;
                background-image: linear-gradient(rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.1) 100%);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                text-shadow: rgb(0 0 0 / 10%) 0px 5px 15px;
                animation: fadeIn 2s ease-in-out;
                line-height: 0.9;
                font-size: 30px;

                &.service {
                    font-size: 43px;
                    line-height: 1.2;

                    @media only screen and (max-width: $breakpoint-mobile) {
                        font-size: 41px;
                    }
                }

                span {
                    font-size: 130px;

                    @media only screen and (max-width: 375px) {
                        font-size: 113px;
                    }
                }
            }

            .ornaments-top {
                background-image: url(../../ornaments.svg);
                background-size: contain;
                background-repeat: no-repeat;
                width: 100%;
                height: 20px;
                background-position: center;
                opacity: .5;
            }

            h5 {
                margin: 0;
                background-image: linear-gradient(rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.5) 100%);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                text-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
                animation: fadeIn 1.5s ease-in-out;
                opacity: .8;
                padding: 0;
                font-family: 'Play';
                font-size: 18.5px;

                @media only screen and (max-width: 375px) {
                    font-size: 16.5px;
                }
            }

            p {
                max-width: 400px;
                font-family: 'Gudea';
                font-size: 14px;
                font-weight: 300;
                opacity: .9;
                background-image: linear-gradient(rgb(255, 255, 255) 30%, rgb(161 150 150 / 100%) 100%);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                text-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
                margin: 3vh auto;
            }

            .ornaments-bottom {
                background-image: url('../../border.svg');
                background-size: contain;
                background-repeat: no-repeat;
                width: 100%;
                height: 30px;
                background-position: center;
                opacity: .3;
            }

            .title-ornament {
                content: '';
                display: block;
                height: 50px;
                background-image: url(https://slidzo.s3.eu-central-1.amazonaws.com/desertknight/16305703_SL_111019_24950_13-ai.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                filter: invert(1);
                opacity: .5;
                width: 115%;
                transform: translateX(-5%);
                animation: fadeIn 1.5s ease-in-out ;
                transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1) all;
            }

            .title-ornament-top {
                height: 50px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                filter: invert(1);
                opacity: .5;
                background-image: url(https://slidzo.s3.eu-central-1.amazonaws.com/desertknight/16305703_SL_111019_24950_13-ai-svg.svg);
                position: absolute;
                top: -8px;
                width: 115%;
                transform: translateX(-5%);
                transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1) all;
                animation: fadeIn 1.5s ease-in-out backwards;
            }

            @media only screen and (max-width: $breakpoint-mobile) {
                left: 40% !important;
                transform: scale(0.8) translate(-50%, 7vh) !important;
                top: 10% !important;
                width: 100% !important;
            }

            @media only screen and (max-width: $breakpoint-md) {
                transform: scale(.8) translate(10%, -20%);

                &.right {
                    transform: scale(0.8) translate(20%, -26%);
                }

                &.left {
                    transform: scale(0.8) translate(-20%, -20%);
                }
            }
        }

        .hud-details {
            position: absolute;
            width: 100vw;
            display: flex;
            justify-content: space-between;
            left: 0;
            bottom: 0;
            padding: 7vh 5vw;
            box-sizing: border-box;

            @media only screen and (max-width: $breakpoint-md) {
                padding: 4vh 4vw;

                .service-name {
                    gap: 0;
                    justify-content: start;
                    transform: unset;

                    .icon-container {
                        .background {
                            width: 50px;
                            height: 50px;
                        }
                        .icon {
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }
        }

        .service-name {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            pointer-events: all;
            transition: .4s cubic-bezier(0.075, 0.82, 0.165, 1) all;

            .icon-container {
                position: relative;
                opacity: .7;

                .background {
                    background-image: url(https://slidzo.s3.eu-central-1.amazonaws.com/desertknight/ornaments.svg);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 65px;
                    height: 65px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    filter: brightness(0) invert(1);
                    opacity: .3;
                    position: absolute;
                    z-index: -1;
                    transform: translate(-7.5px, -7.5px);
                    top: 0;
                    left: 0;
                }

                .icon {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    z-index: 10;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    background: #ffffffbf;

                    .icon-image {
                        width: 80%;
                        height: 80%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        -webkit-mask-image: linear-gradient(rgb(0 0 0 / 100%), rgba(0, 0, 0, .8));
                        mask-image: linear-gradient(rgb(0 0 0 / 100%), rgba(0, 0, 0, .8));
                    }

                    &.home {
                        .icon-image {
                            filter: invert(1);
                            width: 100%;
                            height: 100%;
                            background-size: 100%;
                            background-position: top;
                            -webkit-mask-image: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, .5));
                            mask-image: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, .5));
                        }
                    }
                }
            }

            .content-container {
                padding-left: 20px;
                color: white;

                h3 {
                    margin: 0;
                    font-family: 'Lateef';
                    font-weight: 700;
                    line-height: .5;
                    letter-spacing: unset;
                    text-transform: uppercase;
                    font-size: 17px;
                    color: #e4aa60;
                    text-shadow: 0 0 10px #cca57bc2;
                }

                p {
                    margin: 0;
                    text-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
                    line-height: 1.3;
                    font-weight: 500;
                    opacity: .6;
                    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, .9));
                    font-family: 'Lateef';
                    font-size: 20px;
                    text-transform: uppercase;
                    line-height: .8;
                    margin-top: 5px;
                }

                @media only screen and (max-width: $breakpoint-md) {
                    h3 {
                        font-size: 16px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }

            @media only screen and (max-width: $breakpoint-mobile) {
                left: 40%;
                transform: scale(0.8) translate(-50%, 0%);
                top: 10%;
                width: 100%;
            }
        }

        .explore-more {
            pointer-events: all;
            opacity: .65;
            cursor: pointer;
            transition: .5s ease-in-out;

            &:hover {
                opacity: 1;
            }

            .subtitle {
                text-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
                filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, .9));
                color: white;
                text-align: center;
                text-transform: uppercase;
                opacity: .55;
                font-family: 'Lateef';
                font-size: 15px;
                line-height: 1;
                font-weight: 700;
            }

            .title {
                text-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
                filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, .9));
                color: white;
                text-align: center;
                text-transform: uppercase;
                opacity: .9;
                font-family: 'Lateef';
                font-size: 22px;
                line-height: 1;
                font-weight: 700;
            }

            &::after {
                content: '';
                height: 18px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                transform: rotate(-180deg);
                filter: brightness(0) invert(1) drop-shadow(1px 1px 3px rgba(0, 0, 0, .1));
                background-image: url(https://slidzo.s3.eu-central-1.amazonaws.com/desertknight/16305703_SL_111019_24950_13-ai.svg);
                width: 100%;
                display: block;
            }

            @media only screen and (max-width: $breakpoint-md) {
                .subtitle {
                    font-size: 13.5px;
                }

                .title {
                    font-size: 19px;
                }
            }

            @media only screen and (max-width: $breakpoint-mobile) {
                position: absolute;
                top: -53px;
                left: 50%;
                transform: translateX(-50%);
            }
            
        }

        .scroll-explore {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            pointer-events: all;
            opacity: .65;

            &::before,
            &::after {
                content: '';
                display: block;
                width: 100px;
                height: 18px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                filter: brightness(0) invert(1) drop-shadow(1px 1px 3px rgba(0, 0, 0, .1));
                background-image: url('https://slidzo.s3.eu-central-1.amazonaws.com/desertknight/16305703_SL_111019_24950_13-ai-svg+(1).svg');
            }

            &::after {
                transform: rotate(-180deg);
            }

            div {
                text-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
                filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, .9));
                color: white;
                font-family: 'Play';
                font-weight: 300;
                font-size: 16px;
                text-align: center;
            }

            @media only screen and (max-width: $breakpoint-md) {
                gap: 5px;

                &::before,
                &::after {
                    width: 70px;
                }

                div {
                    font-size: 13px;
                }
            }

            @media only screen and (max-width: $breakpoint-mobile){
                position: absolute;
                top: -38px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .navigation {
            display: flex;
            justify-content: center;
            align-items: center;
            filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, .9));
            pointer-events: all;

            .element {
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: .3;
                transition: .7s ease all;
                cursor: no-drop;
                
                &:hover {
                    opacity: .7;
                }

                &.active {
                    opacity: 1;
                    cursor: pointer;
                }

                .label {
                    margin: auto;
                    color: white;
                    font-family: 'Lateef';
                    opacity: .7;
                    margin-left: 5px;
                    margin-right: 10px;
                    font-size: 20px;
                }

                .arrow {
                    height: 18px;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    filter: brightness(0) invert(1);
                    background-image: url(../../arrow.svg);
                    width: 18px;
                    margin: 0 auto;
                    cursor: pointer;

                    &.back {
                        transform: rotate(-180deg);
                    }
                }

                @media only screen and (max-width: $breakpoint-md) {
                    .label {
                        font-size: 16px;
                        margin-right: 0;
                    }

                    .arrow {
                        height: 12px;
                        width: 13px;
                    }
                }

                @media only screen and (max-width: $breakpoint-mobile) {
                    
                }
            }

            .separator {
                margin: auto;
                color: white;
                font-family: 'Lateef';
                opacity: .7;
                margin-left: 5px;
                margin-right: 10px;

                @media only screen and (max-width: $breakpoint-mobile) {
                    margin: auto 5px;
                }
            }
        }

        .loader-container {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            background-color: #1e2325;
            transition: .5s ease all;
            font-family: Inter;
            z-index: 101;

            &.no-bg {
                background-color: #1e232500;

                .progress {
                    opacity: 0;
                }
                
                .loader {
                    opacity: .5;
                    transition-delay: 1.5s;
                    animation: unset !important;
                }
            }

            &.fade-out {
                .loader {
                    opacity: 0;
                }
            }

            .container {
                margin: auto;
            }

            .progress {
                color: white;
                font-family: 'Ailerons';
                font-size: 20px;
                opacity: .6;
                margin: auto;
                text-align: center;
                margin-top: 23px;
                position: absolute;
                width: 227px;
                transition: .25s ease all;
            }

            .loader {
                width: 250px;
                height: 300px;
                transform: scaleX(-1);
                background-image: url(../../icon.webp);
                background-image: url(../../DT_logo.svg);
                margin: auto;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                transition: .5s ease all;
                animation: loading forwards 3s ease-in-out infinite;
            }
        }

        .start-menu {
            color: white;
            text-align: center;
            bottom: 10%;
            transition: .5s ease all;
            text-shadow: unset;
            width: fit-content;
            display: flex;
            gap: 6px;
            margin: auto;
            animation: fadeIn 2.5s ease-in-out;
            font-weight: 300;
            opacity: .6;
            pointer-events: all;
            transform: translateY(-20px) scale(.8);
            font-family: Play;
            background: #7f787842;
            height: fit-content;
            margin-top: 35px;
            padding: 7px 15px;
            padding-left: 10px;
            border-radius: 20px;
            border: 1px solid #ffffffb8;
            backdrop-filter: blur(10px);
            cursor: pointer;

            @media only screen and (max-width: $breakpoint-mobile) {
                display: none;
            }

            &:hover {
                transform: translateY(-20px) scale(.85);
                filter: drop-shadow(2px 2px 7px #fbd5a4);
            }

            &.pressed {
                filter: drop-shadow(2px 2px 7px #fbd5a4);
            }

            div {
                margin: auto 0;
            }

            .btn {
                color: #4d5e73;
                border-radius: 50%;
                height: 24px;
                width: 24px;
                margin: 0 2px;
                box-shadow: unset;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: .2s ease all;
                transform: scale(1.05);
                // box-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;
                background: white;
                font-weight: 300;
                font-family: 'Play';
            }
        }
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    max-width: 100vw;
    box-sizing: border-box;
    z-index: 101;
    .logo {
        width: 100px;
        height: 100px;
        background-position: top;
        background-repeat: no-repeat;
        background-size: 80%;
        background-image: url(../../icon.webp);
        opacity: .6;
        cursor: pointer;
        transition: .4s ease all;
        pointer-events: all;

        &:hover {
            opacity: .8;
            filter: drop-shadow(2px 2px 7px rgba(255, 255, 255, 0.2));
        }
    }

    .header-titles {
        display: flex;
        color: white;
        gap: 2vw;
        padding: 20px 15px;
        transition: .3s ease all;
        /*line-height: 1;
        text-align: center;*/

        &.mobile {
            display: none;
        }

        div {
            height: fit-content;
            pointer-events: all;
            cursor: pointer;
            font-family: Lateef;
            font-size: 20px;
            opacity: .6;
            transition: .25s ease-in-out;
            color: white;
            &:hover {
                opacity: 1;
            }

            &.active {
                position: relative;
                filter: drop-shadow(2px 2px 7px #fbd5a4);
                opacity: 1;
                color: #fbd5a4;

                .active-child {
                    position: absolute;
                    width: 120%;
                    height: 50px;
                    background-image: url(https://slidzo.s3.eu-central-1.amazonaws.com/desertknight/ornament2.svg);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    bottom: -90%;
                    left: -9%;
                    z-index: -1;
                    filter: brightness(0) invert(1);
                    opacity: 1;
                    -webkit-mask-image: linear-gradient(transparent 50%, rgb(0 0 0 / 100%));
                    mask-image: linear-gradient(transparent 50%, rgb(0 0 0 / 100%));
                    animation: fadeIn 1s ease-in-out;
                }
            }

            &.menu {
                display: flex;
                align-items: center;
                position: relative;

                &:hover {
                    .menu-items {
                        opacity: 1;
                        pointer-events: all;
                    }
                }

                .menu-items {
                    position: absolute;
                    top: 0;
                    transform: translateY(25px);
                    opacity: 0;
                    transition: .2s ease all;
                    pointer-events: none;
                }
            }
        }
    }

    @media only screen and (max-width: $breakpoint-md) {
        .logo {
            transform: scale(.8) translate(-30%, -30%);
        }

        .header-titles {
            &.desktop {
                display: none;
            }

            &.mobile {
                display: flex;

                .active {
                    font-size: 18px !important;
                }
                .active-child {
                    height: 27px !important;
                    bottom: -30% !important;
                }
            }
        }
    }
}

.section-card-container {
    .buttons-container {
        width: 100%;
        display: flex;
        margin-top: 20px;

        .button {
            color: white;
            font-family: 'Inter';
            font-weight: 500;
            font-size: 12px;
            text-align: center;
            align-items: center;
            justify-content: center;
            display: flex;
            padding: 11px 20px;
            //border: 1.8px solid rgba(255,255,255,.2);
            border-radius: 4px;
            opacity: .9;
            pointer-events: all;
            position: relative;
            //clip-path: polygon(0 0, 90% 0, 100% 30%, 100% 80%, 100% 100%, 0 100%, 0% 80%, 0% 20%);
            background-color: rgba(255, 255, 255, 0.408);

            border-radius: 2px 2px 5px 5px;
            background: #9fb7e24f;
            background: #283b5c3b;
            border: 1px solid #ffffff40;
            font-weight: 400;
            backdrop-filter: blur(3px);
            width: 100%;
            cursor: pointer;
            transition: .5s ease all;

            &:hover {

                &::after {
                    opacity: 0;
                }
            }

            &::before {
                display: none;
                content: '';
                display: block;
                position: absolute;
                top: 1px;
                left: 1px;
                background: rgb(37 33 33 / 55%);
                clip-path: polygon(0 0, 90% 0, 100% 30%, 100% 80%, 100% 100%, 0 100%, 0% 80%, 0% 20%);
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                z-index: -1;
                border-radius: 4px;
            }

            &::after {
                transition: .25s ease all;
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                top: 0;
                z-index: 10;
                background-size: 150%;
                background-position: center;
                opacity: .2;
                mix-blend-mode: hue;
                background: url(https://img.freepik.com/free-vector/waves-colorful-points-digital-data-splash-point-array-futuristic-smooth-glitch-ui-element_1217-6307.jpg);
            }
        }
    }
}

.section-card {
    backdrop-filter: blur(3px);
    box-sizing: border-box;
    width: fit-content;
    background: radial-gradient(121.65% 100% at 32.68% 0, rgba(59, 57, 57, 0.93) 0, rgba(33, 33, 33, 0.68) 32.49%, rgba(60, 65, 85, 0) 51.34%), radial-gradient(91.41% 43.04% at 50% 0, rgba(33, 33, 33, 0.5568627451) 20.67%, rgba(26, 28, 36, 0) 100%), radial-gradient(69.96% 25.89% at 50% 100%, rgba(33, 33, 33, 0.5568627451) 22.77%, rgba(19, 21, 27, 0) 100%);
    box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%), inset -1.2000000477px 0 rgb(255 255 255 / 4%), inset 0 1.2000000477px rgb(255 255 255 / 10%), inset 0 0.6000000238px rgb(255 255 255 / 10%), rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    padding: 2rem 0;
    border-radius: 20px 5px;
    pointer-events: all;
    color: white;
    text-align: left !important;
    border-radius: 15px 15px 2px 2px;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        background: url("https://slidzo.s3.eu-central-1.amazonaws.com/totalpng.com+-+5834+(1).png");
        height: 117px;
        top: 0;
        background-size: contain;
        background-repeat: no-repeat;
        transform: translateY(-51%);
        z-index: -1;
        left: 0;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 10;
        background-size: 150%;
        background-position: center;
        opacity: .2;
        mix-blend-mode: hue;
        background: url(https://img.freepik.com/free-vector/waves-colorful-points-digital-data-splash-point-array-futuristic-smooth-glitch-ui-element_1217-6307.jpg);
    }

    .subtitle {
        width: 100%;
        font-family: 'Inter';
        text-transform: uppercase;
        font-size: 12px;
        text-align: left;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        opacity: 0.9;
        text-shadow: 0 0 20px rgb(204 165 123 / 70%);
        background-image: linear-gradient(#e37a20 20%, #ffaf7c 100%);
        font-weight: 700;
        padding: 0 2rem;
        box-sizing: border-box;
    }

    h3 {
        margin: 0;
        font-size: 46px;
        font-family: Ailerons;
        text-transform: uppercase;
        background-image: linear-gradient(rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.1) 100%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        animation: fadeIn 2s ease-in-out;

        text-align: center;
        text-shadow: rgb(0 0 0 / 50%) 0px 5px 15px;
        width: fit-content;
        padding: 0 2rem;
    }

    p {
        text-align: left;
        font-family: 'Inter';
        font-weight: 300;
        font-size: 13px;
        opacity: .7;
        max-width: 390px;
        display: flex;
        padding: 0 2rem;
    }
}

.explore_more {
    width: 100vw;
    min-height: 100vh;
    background-color: rgb(193 191 191 / 45%);
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: all;
    transition: 0.5s ease-in-out;
    backdrop-filter: blur(12px);
    box-shadow: inset 0 1px 1px 0 #c7d3ea1f, inset 0 24px 48px 0 #c7d3ea0d, 0 24px 32px 0 #06060eb3;
    background: radial-gradient(50% 50% at 50% 50%, #4b71fa54 0, #05050b00 100%);
    background-color: rgb(193 191 191 / 21%);
    
    .exp_content_scroll {
        overflow-y: scroll;
        max-height: 100vh;
        scroll-snap-type: y mandatory;
        scroll-behavior: smooth;
        animation: fadeIn 1s ease-in-out;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .exp_overlay {
        width: 100%;
        height: 50vh;
        background-image: url('../../ornaments_bg.svg');
        background-size: contain;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        opacity: .1;
        z-index: -1;
    }

    .options {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 80px;
        padding: 0 40px;
        box-sizing: border-box;

        .scroll-more {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            opacity: 0;
            transition: .5s ease all;

            i {
                height: 18px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                filter: brightness(0) invert(1);
                background-image: url(http://localhost:3000/static/media/arrow.c8eba85ef948e272fa94.svg);
                width: 18px;
                margin: 0 auto;
                cursor: pointer;
                transform: rotate(90deg);
                transition: .5s ease all;
                animation: idleChevron 2.3s ease-in-out infinite;
            }

            &.visible {
                transition: .5s ease all;
                opacity: 1;
            }
        }

        .close {
            color: white;
            font-family: 'Play';
            font-size: 13px;
            text-transform: uppercase;
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: .7;
            transition: .5s ease all;
            cursor: pointer;
            gap: 5px;
            
            &.right {
                margin: auto;
                margin-right: 0;
            }

            i {
                background-image: url('../../x.png');
                background-size: contain;
                background-repeat: no-repeat;
                width: 14px;
                height: 14px;
                display: inline-block;
                transform: rotate(-180deg);
                filter: brightness(0) invert(1);
            }

            span {
                border: 2px solid #ffffffba;
                border-radius: 3px;
                padding: 4px 7px;
                transform: scale(.7);
                text-transform: uppercase;
            }

            p {
                margin: 0;
            }

            &:hover {
                opacity: 1;
                filter: drop-shadow(2px 2px 7px #fff);
            }
        }

        &.contact {
            @media only screen and (max-width: $breakpoint-md){
                bottom: unset;
                top: 5%;
                padding-right: 5%;
            }
        }
    }

    .exp_content {
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        margin: auto;
        display: flex;

        height: fit-content;
        flex-direction: column;
        gap: 50px;

        .use_case_container, .contact_us_container {
            height: 100vh;
            display: flex;
            scroll-snap-align: start;

            &:nth-child(2n) {
                .use_case {
                    flex-direction: row-reverse;
                }
            }
        }

        .use_case, .contact_us {
            margin: auto;
            display: flex;
            width: 90%;
            height: 50%;
            gap: 15px;
            max-width: 1200px;

            .image {
                width: 50%;
                height: 100%;
                background-image: url(https://trendspace.netlify.app/static/GD_2-36b830e95fc372efbfb3e40244bcee0a.jpg);
                background-color: rgba(79, 84, 87, .722);
                background-position: 50%;
                background-size: cover;
                border-radius: 7px;
                box-shadow: 2px 4px 16px rgba(0, 0, 0, .5);
                min-height: 347px;
            }

            .text_container {
                color: white;
                width: 45%;
                font-family: 'Play';
                height: fit-content;
                margin: auto;

                h4 {
                    margin: 0;
                    font-weight: 200;
                    font-size: 11px;
                    text-transform: uppercase;
                    filter: drop-shadow(2px 2px 7px #fbd5a4);
                    opacity: 1;
                    color: #fbd5a4;
                }

                h3 {
                    margin: 0;
                    font-size: 29px;
                    opacity: .7;
                    width: fit-content;
                    filter: drop-shadow(2px 4px 9px rgba(0, 0, 0, 1));

                    div {
                        height: 18px;
                        background-position: right;
                        background-size: contain;
                        background-repeat: no-repeat;
                        transform: rotate(-180deg);
                        filter: brightness(0) invert(1) drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.1));
                        background-image: url(https://slidzo.s3.eu-central-1.amazonaws.com/desertknight/16305703_SL_111019_24950_13-ai.svg);
                        background-image: url('../../16305703_SL_111019_24950_13-ai-svg.svg');
                        width: 100%;
                        display: block;
                        opacity: .5;
                        display: none;
                    }
                }
                p {
                    margin: 0;
                    font-size: 13px;
                    opacity: 1;
                    font-family: 'Gudea';
                    font-weight: 300;
                    letter-spacing: .05em;
                    margin-top: 15px;
                    background-image: linear-gradient(rgb(255, 255, 255) 30%, rgb(205 205 205) 100%);
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
                    text-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
                    text-align: justify;
                    margin-bottom: 20px;
                    filter: drop-shadow(2px 4px 9px rgba(0, 0, 0, .6));

                    &::after {
                        content: "";
                        height: 18px;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        transform: rotate(-180deg);
                        filter: brightness(0) invert(1) drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.1));
                        background-image: url(https://slidzo.s3.eu-central-1.amazonaws.com/desertknight/16305703_SL_111019_24950_13-ai.svg);
                        width: 100%;
                        display: block;
                        margin-top: 20px;
                        opacity: .6;
                    }
                }
                .contact-us {
                    text-transform: uppercase;
                    font-size: 11px;
                    opacity: .7;
                    text-align: right;
                    cursor: pointer;
                    transition: .4s cubic-bezier(0.445, 0.05, 0.55, 0.95) all;
                    margin-top: 5px;
                    margin: auto;
                    margin-right: 0px;
                    margin-top: 5px;
                    width: fit-content;
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    &:hover {
                        opacity: 1;
                        filter: drop-shadow(2px 2px 7px #fff);
                    }

                    i {
                        background-image: url('../../chevron-right.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 16px;
                        height: 16px;
                        display: inline-block;
                    }
                }
            }

            @media only screen and (max-width: $breakpoint-md) {
                width: 90%;

                .image {
                    min-height: unset;
                }

                .text_container {
                    h4 {
                        font-size: 10px;
                    }

                    h3 {
                        font-size: 22px;
                    }

                    p {
                        font-size: 12px;

                        &::after {
                            height: 16px;
                        }
                    }
                }
            }

            @media only screen and (max-width: $breakpoint-md) {
                justify-content: center;
                flex-direction: column !important;
                height: 100vh;

                .image {
                    height: 30vh;
                    min-height: 30vh;
                    width: 100%;
                }

                .text_container {
                    width: 100%;
                    margin: 0;
                }
            }
        }

        .contact_us_container {
            height: 120vh;
            scroll-behavior: smooth;
            .contact_us {
                margin-top: 20vh;
            }
            .contact_card {
                width: 45%;
                background: #ffffff57;
                border-radius: 4px;
                filter: drop-shadow(3px 4px 6px rgba(0, 0, 0, .5));
                padding: 16px;
                box-sizing: border-box;

                color: white;
                padding: 3rem;
                height: fit-content;
                margin: 0 auto;
                min-height: 467px;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                font-family: 'Play';

                border-radius: 10px;
                background: radial-gradient(96.87% 167.08% at 50% 0%, rgba(5, 5, 30, 0) 0%, #fbd5a421 33.33%, #e8a44d12 66.67%, rgba(255, 189, 122, .16) 99.99%, rgba(255, 189, 122, 0) 100%), #fbd5a41f;
                box-shadow: 0 -28px 84px -24px #e2e8ff1f inset;
                border: 1px solid rgba(226, 232, 255, .2);

                .card_header {
                    text-align: center;

                    h3 {
                        font-size: 29px;
                        opacity: 0.7;
                        font-weight: 700;
                        margin: 0;
                    }

                    h5 {
                        font-size: 14px;
                        font-weight: 200;
                        opacity: .7;
                        margin: 0;
                    }
                }

                form {
                    width: 90%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 1.3rem;
                    .input-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 0px;

                        label {
                            color: #ffffffb3;
                            margin-bottom: 8px;
                        }
                        
                        input[type='text'], input[type='email'], textarea {
                            outline: none;
                            border: 0;
                            background: transparent;
                            color: white;
                            font-family: 'Play';
                            border-bottom: 1px solid #ffffffad;
                            padding: 6px 5px;
                            font-weight: 200;
                            resize: none;
                            background-color: rgba(255, 255, 255, 0);
                            border-radius: 4px 4px 0 0;
                            line-height: 1.5;
                            transition: .5s ease all;
                            
                            &::placeholder {
                                color: rgba(255, 255, 255, 0.514);
                                font-weight: 300;
                            }

                            &:focus {
                                background-color: #ffffff1f;
                                border-bottom: 1px solid white;
                            }
                        }
                    }

                    .details-button {
                        text-align: center;
                        align-items: center;
                        align-self: center;
                        border-radius: 10px;
                        box-sizing: border-box;
                        background: #fbd5a4;
                        cursor: pointer;
                        display: flex;
                        font-size: 14px;
                        font-weight: 700;
                        gap: 7px;
                        justify-content: center;
                        margin: 0;
                        opacity: .6;
                        padding: 12px;
                        text-transform: unset;
                        transition: all .5s ease;
                        width: 100%;
                        color: #878b95;
                        filter: drop-shadow(2px 2px 11px #fbd5a48d);
                        align-items: center;
                        -webkit-backdrop-filter: blur(6px);
                        backdrop-filter: blur(6px);
                        box-shadow: inset 0 -7px 11px #a48fff1f;
                        display: flex;
                        position: relative;
                        background: #fbd5a4;
                        color: white;
                        text-shadow: 1px 2px 11px #0000006b;

                        &:hover {
                            filter: drop-shadow(2px 2px 11px #fbd5a4);
                        }
                    }
                }
            }

            .text_container {
                h3 {
                    width: 100%;
                    text-align: center;
                    filter: unset;
                }

                h4 {
                    text-align: center;

                    font-size: 14px;
                    text-transform: unset;
                }

                p {
                    text-align: center;
                    background: no-repeat;
                    color: #ffffffab;
                    -webkit-text-fill-color: unset;
                    font-size: 14px;
                    filter: unset;

                    &::after {
                        display: none;
                    }
                }

                .contact_info {
                    margin-bottom: 12px;

                    h3 {
                        font-size: 15px;
                        text-align: center;
                        width: 100%;
                        filter: unset;
                    }

                    p {
                        text-align: center;
                        width: 70%;
                        margin: 5px auto;
                        background: no-repeat;
                        color: #ffffffab;
                        -webkit-text-fill-color: unset;
                        filter: unset;
                        user-select: all;
                    }
                }
            }

            @media only screen and (max-width: $breakpoint-md) {
                height: fit-content;
                gap: 8vh;
                height: 170vh;

                .contact_us {
                    height: fit-content;
                    gap: 6vh;
                    margin-top: 17vh;

                    .contact_card {
                        width: 100%;
                        max-height: unset;
                        padding: 20px;

                        .card_header {
                            h3 {
                                font-size: 25px;
                            }

                            h5 {
                                font-size: 12px;
                            }
                        }

                        label {
                            margin: 0;
                            font-size: 14px;
                        }

                        .details-button {
                            font-size: 12px;
                        }
                    }

                    .text_container {
                        h4 {
                            font-size: 12px;
                        }

                        p {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}